// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-app-routes-404-tsx": () => import("./../../../src/app/routes/404.tsx" /* webpackChunkName: "component---src-app-routes-404-tsx" */),
  "component---src-app-routes-about-tsx": () => import("./../../../src/app/routes/about.tsx" /* webpackChunkName: "component---src-app-routes-about-tsx" */),
  "component---src-app-routes-aftersales-parts-index-tsx": () => import("./../../../src/app/routes/aftersales/parts/index.tsx" /* webpackChunkName: "component---src-app-routes-aftersales-parts-index-tsx" */),
  "component---src-app-routes-aftersales-service-form-index-tsx": () => import("./../../../src/app/routes/aftersales/service-form/index.tsx" /* webpackChunkName: "component---src-app-routes-aftersales-service-form-index-tsx" */),
  "component---src-app-routes-aftersales-services-index-tsx": () => import("./../../../src/app/routes/aftersales/services/index.tsx" /* webpackChunkName: "component---src-app-routes-aftersales-services-index-tsx" */),
  "component---src-app-routes-bid-detail-tsx": () => import("./../../../src/app/routes/bid/detail.tsx" /* webpackChunkName: "component---src-app-routes-bid-detail-tsx" */),
  "component---src-app-routes-bid-index-tsx": () => import("./../../../src/app/routes/bid/index.tsx" /* webpackChunkName: "component---src-app-routes-bid-index-tsx" */),
  "component---src-app-routes-contact-index-tsx": () => import("./../../../src/app/routes/contact/index.tsx" /* webpackChunkName: "component---src-app-routes-contact-index-tsx" */),
  "component---src-app-routes-faq-index-tsx": () => import("./../../../src/app/routes/faq/index.tsx" /* webpackChunkName: "component---src-app-routes-faq-index-tsx" */),
  "component---src-app-routes-feedback-index-tsx": () => import("./../../../src/app/routes/feedback/index.tsx" /* webpackChunkName: "component---src-app-routes-feedback-index-tsx" */),
  "component---src-app-routes-index-tsx": () => import("./../../../src/app/routes/index.tsx" /* webpackChunkName: "component---src-app-routes-index-tsx" */),
  "component---src-app-routes-news-detail-tsx": () => import("./../../../src/app/routes/news/detail.tsx" /* webpackChunkName: "component---src-app-routes-news-detail-tsx" */),
  "component---src-app-routes-news-index-tsx": () => import("./../../../src/app/routes/news/index.tsx" /* webpackChunkName: "component---src-app-routes-news-index-tsx" */),
  "component---src-app-routes-products-categories-tsx": () => import("./../../../src/app/routes/products/categories.tsx" /* webpackChunkName: "component---src-app-routes-products-categories-tsx" */),
  "component---src-app-routes-products-detail-tsx": () => import("./../../../src/app/routes/products/detail.tsx" /* webpackChunkName: "component---src-app-routes-products-detail-tsx" */),
  "component---src-app-routes-products-index-tsx": () => import("./../../../src/app/routes/products/index.tsx" /* webpackChunkName: "component---src-app-routes-products-index-tsx" */),
  "component---src-app-routes-registration-index-tsx": () => import("./../../../src/app/routes/registration/index.tsx" /* webpackChunkName: "component---src-app-routes-registration-index-tsx" */),
  "component---src-app-routes-reports-index-tsx": () => import("./../../../src/app/routes/reports/index.tsx" /* webpackChunkName: "component---src-app-routes-reports-index-tsx" */),
  "component---src-app-routes-showroom-index-tsx": () => import("./../../../src/app/routes/showroom/index.tsx" /* webpackChunkName: "component---src-app-routes-showroom-index-tsx" */),
  "component---src-app-routes-vacancies-detail-tsx": () => import("./../../../src/app/routes/vacancies/detail.tsx" /* webpackChunkName: "component---src-app-routes-vacancies-detail-tsx" */),
  "component---src-app-routes-vacancies-index-tsx": () => import("./../../../src/app/routes/vacancies/index.tsx" /* webpackChunkName: "component---src-app-routes-vacancies-index-tsx" */)
}

