module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#c71444","showSpinner":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/nyala-motors/nyala-motors/www/src/shared/intl","languages":["en","am"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NJKSHC5"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nyala Motors S.C.","short_name":"Nyala Motors","description":"Official website of Nyala Motors S.C.","lang":"en","display":"standalone","icon":"src/assets/images/shared/brand/icon.png","start_url":"/","background_color":"rgb(255, 255, 255)","theme_color":"#bd0504","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a59251abef4f0db3faf7e70aac759489"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"]},
    }]
